<template>
    <v-card>
        <v-card-title class="text-h3 mb-2">Historique personnel</v-card-title>
        <v-card-subtitle>Consultez votre historique récent.</v-card-subtitle>
        <v-card-text>
            <v-list>
                <v-list-item link v-for="(item, index) in personal_history" :key="index">
                    <div class="d-flex" style="width:100%;align-items: center">
                        <span style="width:20%">{{ item.date_connexion }}</span>
                        <span style="width:20%">{{ item.ip_adress }}</span>
                        <span style="width:30%">{{ item.computer_name }}</span>
                    </div>
                </v-list-item>
            </v-list>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" :disabled="isChanges()" @click="resetSettings">Annuler</v-btn>
            <v-btn color="success" :disabled="isChanges()" @click="saveSettings">Enregistrer</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    name : 'PersonalHistory',
    data() {
        return {
            personal_history : JSON.parse(localStorage.getItem('settings')).main.personal_history,
            personal_history_backup : JSON.parse(localStorage.getItem('settings')).main.personal_history,
        }
    },
    methods : {
        saveSettings() {
            if (this.personal_history_backup !== this.personal_history) {
                const settings = JSON.parse(localStorage.getItem('settings'));
                settings.main.personal_history = this.personal_history;
                this.personal_history_backup = JSON.parse(JSON.stringify(this.personal_history));
                localStorage.setItem('settings', JSON.stringify(settings));
            }
        },
        resetSettings() {
            this.personal_history = JSON.parse(JSON.stringify(this.personal_history_backup));
        },
        isChanges () {
            if (JSON.stringify(this.personal_history_backup) !== JSON.stringify(this.personal_history)) {
                return false;
            } else {
                return true;
            }
        }
    },
}
</script>